import React from 'react'
import Helmet from 'react-helmet'

import { LargeTitle, Title, Title2, Title3, Headline, Statement, Callout, Subhead, Body, Caption } from '../styles/typography'

import { PageLayout } from 'layouts/PageLayout'

export default () => {
  return (
    <PageLayout>
      <Helmet title={'Floating Points | Typography'} />
      <LargeTitle>This is a large title</LargeTitle>
      <Title>This is a title</Title>
      <Title2>This is title two</Title2>
      <Title3>This is title three</Title3>
      <Headline>This is a headline</Headline>
      <Statement>This is a statement</Statement>
      <Callout>This is a callout</Callout>
      <Subhead>This is a subheading</Subhead>
      <Body>This is body text</Body>
      <Caption>This is a caption</Caption>
    </PageLayout>
  )
}

